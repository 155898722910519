import { useMobile } from 'presentation/hooks'
import { TouchEvent, useCallback, useEffect, useState } from 'react'

export function useMediaBannerCarouselController({
  ref,
  slides,
  interval = 8000,
  autoPlay = true,
  beforeChandeSlide
}) {
  const isMobile = useMobile()
  const [lockArrows, setLockArrows] = useState(false)
  const [position, setPosition] = useState(0)
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)
  const [hasTouch, setHasTouch] = useState(false)
  const minSwipeDistance = 50

  function handleTouchStart(e: TouchEvent<HTMLDivElement>) {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  function handleTouchMove(e: TouchEvent<HTMLDivElement>) {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  function handleTouchEnd() {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (isLeftSwipe) handleNext()
    if (isRightSwipe) handlePrev()
  }

  const DIRECTION_TYPE = {
    next: 'NEXT',
    prev: 'PREV'
  }

  const getSlidesPositions = useCallback(
    (currentIndexPosition) => {
      if (slides.length <= 2) {
        return {
          previous:
            currentIndexPosition >= slides.length - 1
              ? slides[0]
              : slides[currentIndexPosition + 1],
          current: slides[currentIndexPosition]
        }
      }

      return {
        previous:
          currentIndexPosition <= 0
            ? slides[slides.length - 1]
            : slides[currentIndexPosition - 1],
        current: slides[currentIndexPosition],
        next:
          currentIndexPosition >= slides.length - 1
            ? slides[0]
            : slides[currentIndexPosition + 1]
      }
    },
    [slides]
  )

  function handleTransform({ elements, forwards, hasTwoSlides }) {
    const { prevElement, currentElement, nextElement } = elements
    prevElement.style.transform =
      forwards || hasTwoSlides ? 'translateX(0%)' : 'translateX(100%)'

    currentElement.style.transform = forwards
      ? 'translateX(100%)'
      : 'translateX(-100%)'

    if (nextElement) {
      nextElement.style.transform = forwards
        ? 'translateX(-100%)'
        : 'translateX(0)'
    }
  }

  function handleAnimation({ elements, forwards, hasTwoSlides }) {
    const { prevElement, currentElement, nextElement } = elements
    setTimeout(() => {
      prevElement.style.transition = ''
      currentElement.style.transition = ''
      prevElement.style.zIndex = forwards || hasTwoSlides ? '2' : '-1'
      currentElement.style.zIndex = '3'

      if (nextElement) {
        nextElement.style.transition = ''
        nextElement.style.zIndex = forwards ? '-1' : '2'
      }

      prevElement.style.transform =
        !forwards && hasTwoSlides ? 'translateX(100%)' : 'translateX(-100%)'

      currentElement.style.transform = 'translateX(0%)'

      if (nextElement) {
        nextElement.style.transform = 'translateX(100%)'
      }
    })
  }

  const handleElementsStyles = useCallback(
    ({ direction, currentIndexPosition }) => {
      const { previous, current, next } =
        getSlidesPositions(currentIndexPosition)
      const hasTwoSlides = slides.length === 2
      const forwards = direction === DIRECTION_TYPE.next
      const elements = {
        prevElement: ref.current[previous],
        currentElement: ref.current[current],
        nextElement: ref.current[next]
      }

      slides.forEach((slide: string) => {
        ref.current[slide].style.transition = '0s'
      })

      handleTransform({ elements, forwards, hasTwoSlides })
      handleAnimation({ elements, forwards, hasTwoSlides })
    },
    [DIRECTION_TYPE.next, getSlidesPositions, ref, slides]
  )

  const getPosition = useCallback(
    (direction) => {
      return {
        [DIRECTION_TYPE.prev]: position > 0 ? position - 1 : slides.length - 1,
        [DIRECTION_TYPE.next]: position < slides.length - 1 ? position + 1 : 0
      }[direction]
    },
    [DIRECTION_TYPE.next, DIRECTION_TYPE.prev, position, slides.length]
  )

  const changeSlide = useCallback(
    (direction) => {
      if (lockArrows || slides.length <= 1) return
      setLockArrows(true)
      setTimeout(() => {
        setLockArrows(false)
      }, 1000)
      const currentIndexPosition = getPosition(direction)
      beforeChandeSlide(currentIndexPosition)
      setTimeout(() => {
        setPosition(currentIndexPosition)
        handleElementsStyles({ direction, currentIndexPosition })
      }, 100)
    },
    [getPosition, handleElementsStyles, lockArrows, slides.length]
  )

  function handlePrev() {
    changeSlide(DIRECTION_TYPE.prev)
  }

  const handleNext = useCallback(() => {
    changeSlide(DIRECTION_TYPE.next)
  }, [DIRECTION_TYPE.next, changeSlide])

  useEffect(() => {
    if (!autoPlay) return
    const goToNextSlide = setTimeout(() => {
      handleNext()
    }, interval)
    return () => {
      clearTimeout(goToNextSlide)
    }
  }, [interval, handleNext, autoPlay])

  useEffect(() => {
    setHasTouch(isMobile && 'ontouchstart' in document.documentElement)
  }, [isMobile])

  return {
    handlePrev,
    handleNext,
    lockArrows,
    hasTouch,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  }
}
