import * as S from './MegaFairContent.styles'

export function MegaFairContent({ appUrl, handleClose, handleCTAClick }) {
  return (
    <S.Container className="modal-mega-fair">
      <S.Content>
        <S.Box className="close_modal_login" onClick={handleClose}>
          <S.Icon icon="SLIM_CLOSE" />
        </S.Box>
        <img
          src="/novo-portal/static/assets/MegaFeirao2025.svg"
          alt="Logo Tema - MegaFeirão"
        />
        <S.Typography variant="h4">
          Baixe o App para encontrar <br /> as ofertas relâmpago <br /> abaixo
          da Tabela Fipe.
        </S.Typography>
        <S.Typography variant="body2">
          Condições válidas até 13/04/2025. Crédito sujeito a análise e <br />
          condições vigentes no momento da contratação. Taxa especial para
          <br />
          financiamento exclusivo com o Banco Santander.
        </S.Typography>
        <S.Button
          href={appUrl}
          formTarget="_blank"
          id="btn_to_app"
          variant="primary"
          onClick={handleCTAClick}
        >
          Baixe o App agora
        </S.Button>
      </S.Content>
    </S.Container>
  )
}
