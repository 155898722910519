import styled, { css } from 'styled-components'

export const ButtonGroupContainer = styled.div`
  display: inline-flex;
  gap: 8px;
  height: 40px;
  margin-bottom: 16px;

  @media (max-width: 899px) {
    height: 24px;
    margin-bottom: 20px;
  }
`

export const Button = styled.button<{ active: boolean }>`
  border: none;
  background-color: #edecf2;
  color: #6a6977;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 20px;
  width: 90px;

  @media (max-width: 899px) {
    width: 70px;
    font-size: 12px;
    padding: 0;
  }

  &:hover {
    background-color: #d0cfda;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #ffd3dc;
      color: #e22446;

      &:hover {
        background-color: #ffd3dc;
      }
    `}
`
