import { useMediaBannerController } from 'presentation/pages/home/components/MediaBanner/MediaBannerController'
import { useEffect, useRef } from 'react'
import * as S from './MediaBannerCarousel.styles'
import { useMediaBannerCarouselController } from './MediaBannerCarouselController'

export function MediaBannerCarousel({ autoPlay, ...rest }) {
  const carouselRef = useRef<{ current: HTMLDivElement | null }>({
    current: null
  })

  const { slotIds, handleRegisterZoneBanner } = useMediaBannerController()

  const {
    handlePrev,
    handleNext,
    lockArrows,
    hasTouch,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  } = useMediaBannerCarouselController({
    ref: carouselRef,
    slides: slotIds,
    autoPlay,
    beforeChandeSlide: (eminentSlideIndex) => {
      handleRegisterZoneBanner(eminentSlideIndex)
    }
  })

  useEffect(() => {
    handleRegisterZoneBanner(0)
  }, [])

  return (
    <S.Carousel
      data-testid="media-banner-carousel"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      {...rest}
    >
      <S.CarouselWrapper>
        {slotIds.map((slide: string) => (
          <S.CarouselItem
            ref={(el) => (carouselRef.current[slide] = el)}
            data-qa="Carousel_Banner"
            data-testid={slide}
            id={slide}
            key={slide}
          />
        ))}
      </S.CarouselWrapper>
      {slotIds.length > 1 && !hasTouch && (
        <>
          <S.PrevButton
            disabled={lockArrows}
            data-role="none"
            data-testid="prev-button"
            type="button"
            onClick={handlePrev}
          >
            Anterior
          </S.PrevButton>
          <S.NextButton
            disabled={lockArrows}
            data-role="none"
            data-testid="next-button"
            type="button"
            onClick={handleNext}
          >
            Próximo
          </S.NextButton>
        </>
      )}
    </S.Carousel>
  )
}
