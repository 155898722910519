import styled from 'styled-components'

export const Card = styled.a`
  padding: 16px;
  border: 1px solid #d0cfda;
  background: #fff;
  border-radius: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: inline-block;
    background: none;
    border: none;
    padding: 0;
    width: 64px;
    margin: 0 8px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: block;
    position: relative;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 8px;
  border-radius: 100px;
  background: #ffeaea;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: relative;
    margin: 0 auto;
    background: #fff;
    width: 64px;
    height: 64px;
  }
`

export const Badge = styled.span`
  display: flex;
  height: 16px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #e22446;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 8px;
    padding: 2px 8px 0px;
    height: 12px;
  }
`

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.palette.secondary.light};
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  padding: 12px 0 8px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    font-size: 11px;
    line-height: 150%;
    width: 70px;
    margin: 0 auto;
    padding: 4px 0 0;
  }
`

export const CardText = styled.p`
  color: #6a6977;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`
