import { useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'
import * as Styles from './WebmotorsSolutionsCard.styles'
import { Props } from './WebmotorsSolutionsCard.types'

export function WebmotorsSolutionsCard({
  id,
  url,
  data_qa,
  attributes,
  imageAlt,
  image,
  spanText,
  titleDesktop,
  titleMobile,
  mainText
}: Props) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const MemoizedImage = useMemo(
    () => <img src={image} alt={imageAlt} />,
    [image, imageAlt]
  )

  return (
    <Styles.Card
      href={url}
      id={id}
      data-qa={data_qa}
      data-testid="WebmotorsSolutionsCard"
      {...attributes}
    >
      <Styles.HeaderContainer>
        <Styles.ImageContainer>{MemoizedImage}</Styles.ImageContainer>
        {spanText && <Styles.Badge>{spanText}</Styles.Badge>}
      </Styles.HeaderContainer>
      <Styles.CardTitle>
        {isDesktop ? titleDesktop : titleMobile}
      </Styles.CardTitle>
      <Styles.CardText>{mainText}</Styles.CardText>
    </Styles.Card>
  )
}
