import { loggerClient } from 'application/Services'

const logger = loggerClient.create('SlotService')

export class Slot {
  /**
   * Slot ad unit
   *
   */
  adUnit: string

  /**
   * Slot html id
   *
   */
  htmlId: string

  /**
   * Slot target
   *
   */
  target: googletag.Slot

  /**
   * Slot targets
   *
   */
  targets?: [string, string]

  /**
   * size to googletag
   */
  size?: googletag.GeneralSize

  /**
   * define if Constructs an out-of-page ad slot with the given ad unit path.
   */
  outOfPageSlot?: boolean

  /**
   * Slot constructor method
   *
   * @param params
   */
  constructor(params: Partial<Slot>) {
    Object.assign(this, params)

    if (!this.outOfPageSlot && !this.size) {
      throw new Error('size é obrigatório quando outOfPageSlot é false.')
    }

    googletag.cmd.push(() => {
      const targetSlot = this.outOfPageSlot
        ? googletag?.defineOutOfPageSlot?.(this.adUnit, this.htmlId)
        : googletag?.defineSlot?.(this.adUnit, this.size!, this.htmlId)

      if (targetSlot) {
        this.defineTargetSlot(targetSlot)
      }
    })
  }

  private defineTargetSlot(targetSlot: googletag.Slot) {
    if (this.targets) {
      logger.info(
        `[createSlot] add targets to slog ${this.htmlId}:`,
        this.targets
      )
      targetSlot.setTargeting(...this.targets)
    }
    targetSlot.addService(googletag.pubads())

    this.target = targetSlot
  }
}
