import { Typography as DSTypography } from 'presentation/components'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 32px 30px 20px;
  max-width: 1020px;
  margin: 0px auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0px;
    padding-top: ${(props) => props.theme.spacing(3)};
    padding-bottom: 12px;
  }
`

export const Typography = styled(DSTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${(props) => props.theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: ${(props) => props.theme.spacing(3)};
  }
`

export const CardRow = styled.div`
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: ${(props) => props.theme.spacing(2)};
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 24px;
    display: block;

    & > *:first-child {
      margin-left: 0;
    }
  }
`
