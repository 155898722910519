import { Config, webmotorsSolutionService } from 'application'
import window from 'global/window'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { useEffect, useState } from 'react'
import { WebmotorsSolutionsCard } from '../../Cards'
import * as Styles from './WebmotorsSolutions.styles'

const solutions = webmotorsSolutionService.getAllFromConfigData()

function getData() {
  if (!isClientSide()) {
    return
  }

  const subscriptionValue = window?.localStorage?.getItem(
    Config.subscriptionStorageKey
  )

  if (subscriptionValue !== 'B') {
    return solutions.filter(
      (solution) => solution.id !== 'WebmotorsSolution_subscriptionCars'
    )
  }

  return solutions
}

export function WebmotorsSolutions() {
  const [data, setData] = useState(getData())

  useEffect(() => {
    if (!isClientSide()) {
      return
    }

    function fetchData() {
      setData(getData())
    }

    window?.addEventListener('@subscription::ABTest', fetchData)
    return () => {
      window?.removeEventListener('@subscription::ABTest', fetchData)
    }
  }, [])

  return (
    <Styles.Container>
      <Styles.Typography
        variant="h2"
        color="textSecondary"
        mb={2}
        data-testid="WebmotorsSolutionsTitle"
      >
        Soluções Webmotors
      </Styles.Typography>
      <Styles.CardRow>
        {data?.map((solution) => (
          <WebmotorsSolutionsCard key={solution.id} {...solution} />
        ))}
      </Styles.CardRow>
    </Styles.Container>
  )
}
