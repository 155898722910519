import { createTheme } from '@mui/material/styles'
import baseTheme from '../baseTheme'

const defaultTheme = createTheme(baseTheme, {
  palette: {
    primary: {
      light: '#ff3a64',
      main: '#e11138',
      dark: '#bf0d2e'
    },
    secondary: {
      main: '#2e2d37',
      light: '#2e2d37'
    },
    error: {
      main: '#9b51e0'
    },
    neutral: {
      light: '#fff'
    }
  }
})

export default defaultTheme
