import { BaseGetConfigsRepository } from 'domain/BaseRepository'
import { WebmotorsSolution } from '../Model/WebmotorsSolution'
export class WebmotorsSolutionService {
  constructor(
    private configData: BaseGetConfigsRepository<WebmotorsSolution>
  ) {}
  /**
   * Get all available from data config
   *
   * @returns {WebmotorsSolution[]}
   */
  getAllFromConfigData(): WebmotorsSolution[] {
    return this.configData.getAll()
  }
}
