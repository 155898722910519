import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'

@FakableClass()
export class WebmotorsSolution {
  /**
   * WebmotorsSolution id
   *
   */
  @FakableField(() => Faker.string(10))
  id!: string

  /**
   * WebmotorsSolution title for desktop
   *
   */
  @FakableField(() => Faker.string(10))
  titleDesktop!: string

  /**
   * WebmotorsSolution title for mobile
   *
   */
  @FakableField(() => Faker.string(10))
  titleMobile!: string

  /**
   * WebmotorsSolution text for the span
   *
   */
  @FakableField(() => Faker.string(10))
  spanText?: string

  /**
   * WebmotorsSolution text for the main area
   *
   */
  @FakableField(() => Faker.string(10))
  mainText!: string

  /**
   * WebmotorsSolution data_qa
   *
   */
  @FakableField(() => Faker.string(10))
  data_qa!: string

  /**
   * WebmotorsSolution image
   *
   */
  @FakableField(() => Faker.url())
  image!: string

  /**
   * WebmotorsSolution image alt text
   *
   */
  @FakableField(() => Faker.string(10))
  imageAlt!: string

  /**
   * WebmotorsSolution url
   *
   */
  @FakableField(() => Faker.url())
  url!: string

  /**
   * WebmotorsSolution attributes
   *
   */
  @FakableField(() => ({
    rel: 'noreferrer',
    target: '_blank'
  }))
  attributes!: {
    rel: 'noreferrer' | 'nofollow' | 'noopener'
    target: '_blank' | '_self'
    onClick?: () => void
  }

  /**
   * WebmotorsSolution constructor
   *
   * @param params
   */
  constructor(params: Partial<WebmotorsSolution>) {
    Object.assign(this, params)
  }
}
