import { Config } from 'application'
import { BaseGetConfigsRepository } from 'domain/BaseRepository'
import { WebmotorsSolution } from '../Model/WebmotorsSolution'
export class WebmotorsSolutionRepository
  implements BaseGetConfigsRepository<WebmotorsSolution>
{
  getAll(): WebmotorsSolution[] {
    return Config.webmotorsSolutionLinks
  }
}
