import { slotService } from 'domain/Advertisement'
import window from 'global/window'
import { useLocation, useMobile } from 'presentation/hooks'
import { useEffect, useMemo, useState } from 'react'
import { googleTagSettings } from './MediaBanner.config'

export function useMediaBannerController() {
  const [slots, setSlots] = useState<googletag.Slot[]>([])
  const { city } = useLocation()
  const {
    totalSlots,
    networkCode,
    unitCode,
    dimensions,
    idHtml,
    key,
    desktopSize,
    mobileSize
  } = googleTagSettings()
  const isMobile = useMobile()

  const { googletag } = window

  const slotIds = useMemo(() => {
    return Array.from(Array(totalSlots), (_, index) => `${idHtml}${index + 1}`)
  }, [idHtml, totalSlots])

  useEffect(() => {
    googletag.cmd.push(() => {
      googletag.pubads().refresh(slots)
    })
  }, [isMobile])

  useEffect(() => {
    if (!city) return
    const setCity = () => {
      googletag.pubads().setTargeting('uf', city.state.abbr)
      googletag.pubads().setTargeting('city', city.toSlug('_'))
    }

    googletag.cmd.push(setCity)
  }, [city, googletag])

  const slotAlreadyAdded = (index: number) => {
    return slots.find((slot) => slot.getSlotElementId() === slotIds[index])
  }

  const handleRegisterZoneBanner = (index: number) => {
    if (!slotAlreadyAdded(index) && totalSlots > slots.length) {
      googletag.cmd.push(() => {
        const sizeMapping = googletag
          ?.sizeMapping?.()
          .addSize([900, 0], desktopSize)
          .addSize([320, 0], mobileSize)
          .build()

        const slot = slotService.createSlot(
          {
            adUnit: `${networkCode}/${unitCode}`,
            htmlId: slotIds[index],
            size: dimensions,
            targets: [key, `${index + 1}`]
          },
          sizeMapping
        )

        if (slot) {
          setSlots((prevSlots) => [...prevSlots, slot.target])
        }

        googletag.cmd.push(() => {
          googletag.display(slot.target.getSlotElementId())
        })
      })
    }
  }

  return {
    idHtml,
    slotIds,
    handleRegisterZoneBanner
  }
}
